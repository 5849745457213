import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Env from '../../shared/config/envConfig'
import {
  Grid,
  Typography,
  Box,
  useTheme,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import CardComponent from './CardComponent'
import CheckIcon from '@mui/icons-material/Check'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCrudeAppName, getUser } from '../../store/settings/settings.selectors'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '40px',
  },
}))

const Dashboard: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const user = useSelector(getUser)
  const theme = useTheme()
  const appName: string = useSelector(getCrudeAppName)
  const { t } = useTranslation('dashboard')

  const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    [theme.breakpoints.down('md')]: {
      '& td, th': {
        border: 0,
      },
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))

  const [mobileLayout, setMobileLayout] = useState(false)

  const [employmentData, setEmploymentData] = useState<any>()
  const [employmentPlannedData, setEmploymentPlannedData] = useState<any>()
  const [helperData, setHelperData] = useState<any>()

  useEffect(() => {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    const plannedEmploymentRestUrl = `${Env.basePath}/api/dashboard/plannedemployments`
    fetch(plannedEmploymentRestUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        setEmploymentPlannedData(json)
      })
      .catch(err => console.log(err))

    const helperRestUrl = `${Env.basePath}/api/dashboard/assignablehelper`
    fetch(helperRestUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        setHelperData(json)
      })
      .catch(err => console.log(err))

    const employmentRestUrl = `${Env.basePath}/api/dashboard/notoccupiedemployments`
    fetch(employmentRestUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        setEmploymentData(json)
      })
      .catch(err => console.log(err))
  }, [],
  )

  const rootRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (rootRef.current) {
      const element: HTMLElement = rootRef.current

      setMobileLayout(element.offsetWidth < 600)

      const observer = new ResizeObserver(() => {
        setMobileLayout(element.offsetWidth < 600)
      })
      observer.observe(element)

      return () => {
        observer.disconnect(element)
      }
    }
  }, [rootRef, setMobileLayout])

  return (
    <>
      <Grid ref={rootRef} container className={classes.root}>
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ backgroundColor: theme.palette.primary.main, borderRadius: '10px', px: mobileLayout ? 2 : 4, py: mobileLayout ? 2 : 5 }}>
              <Typography variant='h4' sx={{ color: theme.palette.primary.contrastText }}>{`Hallo ${user?.displayName}`}</Typography>
              <Typography variant='h6' sx={{ color: theme.palette.primary.contrastText }}>Willkommen zurück im Admin-Bereich.</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <CardComponent
              height={mobileLayout ? 170 : 240}
            >
              <Stack alignItems='center' justifyContent='center' height='100%'>
                <Box
                  sx={{ position: 'relative' }} onClick={() => {
                    const newPath = `/${appName}/Employment/`
                    history.push(newPath)
                  }}
                >
                  <CalendarTodayIcon sx={{ width: '40px', height: '40px' }} />
                  <Box sx={{ position: 'absolute', top: '14px', left: '10px' }}><b>FR</b></Box>
                </Box>
                <Typography variant='h6'>Eingetragene Helfer</Typography>
                <Typography variant='h4'><b>{`${employmentPlannedData?.frAssigned || 0} / ${employmentPlannedData?.frRequired || 0}`}</b></Typography>
              </Stack>
            </CardComponent>
          </Grid>
          <Grid item xs={4}>
            <CardComponent
              height={mobileLayout ? 170 : 240}
            >
              <Stack alignItems='center' justifyContent='center' height='100%'>
                <Box
                  sx={{ position: 'relative' }} onClick={() => {
                    const newPath = `/${appName}/Employment/`
                    history.push(newPath)
                  }}
                >
                  <CalendarTodayIcon sx={{ width: '40px', height: '40px' }} />
                  <Box sx={{ position: 'absolute', top: '14px', left: '10px' }}><b>SA</b></Box>
                </Box>
                <Typography variant='h6'>Eingetragene Helfer</Typography>
                <Typography variant='h4'><b>{`${employmentPlannedData?.saAssigned || 0} / ${employmentPlannedData?.saRequired || 0}`}</b></Typography>
              </Stack>
            </CardComponent>
          </Grid>
          <Grid item xs={4}>
            <CardComponent
              height={mobileLayout ? 170 : 240}
            >
              <Stack alignItems='center' justifyContent='center' height='100%'>
                <Box
                  sx={{ position: 'relative' }} onClick={() => {
                    const newPath = `/${appName}/Employment/`
                    history.push(newPath)
                  }}
                >
                  <CalendarTodayIcon sx={{ width: '40px', height: '40px' }} />
                  <Box sx={{ position: 'absolute', top: '14px', left: '10px' }}><b>SO</b></Box>
                </Box>
                <Typography variant='h6'>Eingetragene Helfer</Typography>
                <Typography variant='h4'><b>{`${employmentPlannedData?.soAssigned || 0} / ${employmentPlannedData?.soRequired || 0}`}</b></Typography>
              </Stack>
            </CardComponent>
          </Grid>

          <Grid item xs={12} container spacing={3} direction={mobileLayout ? 'column-reverse' : 'row'}>
            <Grid item xs={mobileLayout ? undefined : 6}>
              <CardComponent padding={mobileLayout ? 0 : undefined} height={mobileLayout ? 'unset' : 350} sx={{ overflow: mobileLayout ? 'hidden' : 'scroll' }}>
                <Stack spacing={1}>
                  <Typography sx={{ padding: mobileLayout ? 2 : 0, paddingBottom: 0 }} variant='h5'>Nicht verplante Einsätze</Typography>
                  <TableContainer>
                    <Table>
                      {!mobileLayout &&
                        <TableHead>
                          <StyledTableRow>
                            <TableCell sx={{ fontWeight: 600 }}>Einsatz</TableCell>
                            <TableCell sx={{ fontWeight: 600, textAlign: 'center' }}>Wettkampftag</TableCell>
                            <TableCell sx={{ fontWeight: 600, textAlign: 'center' }}>Benötigte Helfer</TableCell>
                            <TableCell sx={{ fontWeight: 600, textAlign: 'center' }}>Eingeplante Helfer</TableCell>
                          </StyledTableRow>
                        </TableHead>}
                      <TableBody>
                        {employmentData && employmentData.length > 0
                          ? (
                            <>
                              {employmentData?.map((employment: any, index: number) => (
                                <StyledTableRow
                                  sx={{ cursor: 'pointer' }}
                                  key={index}
                                  onClick={() => {
                                    const newPath = employment.id ? `/${appName}/Employment/${employment.id}/` : ''
                                    history.push(newPath)
                                  }}
                                >
                                  {mobileLayout ? (
                                    <TableCell>
                                      <Grid container spacing={1}>
                                        <Grid item xs={6}>Installation</Grid>
                                        <Grid item xs={6}><Typography>{employment.displayName}</Typography></Grid>
                                        <Grid item xs={6}>Installationskey</Grid>
                                        <Grid item xs={6}><Typography>{t(employment.eventDay)}</Typography></Grid>
                                        <Grid item xs={6}>Kunde</Grid>
                                        <Grid item xs={6}><Typography>{t(employment.helperRequirement)}</Typography></Grid>
                                        <Grid item xs={6}>Lizenz</Grid>
                                        <Grid item xs={6}><Typography>{employment.assignedHelperAmount}</Typography></Grid>
                                      </Grid>
                                    </TableCell>)
                                    : (
                                      <>
                                        <TableCell component='th' scope='row'>
                                          {employment.displayName}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{employment.eventDay}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{employment.helperRequirement}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{employment.assignedHelperAmount}</TableCell>
                                      </>)}
                                </StyledTableRow>
                              ))}
                            </>
                          )
                          : (
                            <TableCell colSpan={4} sx={{ width: '100%', textAlign: 'center' }}>
                              <Typography variant='body1'>Alle Einsätze sind vollständig.</Typography>
                            </TableCell>)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </CardComponent>
            </Grid>
            <Grid item xs={mobileLayout ? undefined : 6}>
              <CardComponent padding={mobileLayout ? 0 : undefined} height={mobileLayout ? 'unset' : 350} sx={{ overflow: mobileLayout ? 'hidden' : 'scroll' }}>
                <Stack spacing={1}>
                  <Typography sx={{ padding: mobileLayout ? 2 : 0, paddingBottom: 0 }} variant='h5'>Freie Helfer</Typography>
                  <TableContainer>
                    <Table>
                      {!mobileLayout &&
                        <TableHead>
                          <StyledTableRow>
                            <TableCell sx={{ fontWeight: 600 }}>Helfer</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Verfügbare Tage</TableCell>
                            <TableCell sx={{ fontWeight: 600, textAlign: 'center' }}>Zuvor geholfen</TableCell>
                            <TableCell sx={{ fontWeight: 600, textAlign: 'center' }}>Vereinsmitglied</TableCell>
                          </StyledTableRow>
                        </TableHead>}
                      <TableBody>
                        {helperData && helperData.length > 0
                          ? (
                            <>
                              {helperData?.map((helper: any, index: number) => (
                                <StyledTableRow
                                  sx={{ cursor: 'pointer' }}
                                  key={index}
                                  onClick={() => {
                                    const newPath = helper.id ? `/${appName}/Helper/${helper.id}/` : ''
                                    history.push(newPath)
                                  }}
                                >
                                  {mobileLayout ? (
                                    <TableCell>
                                      <Grid container spacing={1}>
                                        <Grid item xs={6}>Helfer</Grid>
                                        <Grid item xs={6}><Typography>{`${helper.firstName} ${helper.lastName}`}</Typography></Grid>
                                        <Grid item xs={6}>Verfügbare Tage</Grid>
                                        <Grid item xs={6}><Typography>{`${helper.availableOnFriday && !helper.assignedOnFriday ? 'FR' : ''} ${helper.availableOnSaturday && !helper.assignedOnSaturday ? 'SA' : ''} ${helper.availableOnSunday && !helper.assignedOnSunday ? 'SO' : ''}`}</Typography></Grid>
                                        <Grid item xs={6}>Zuvor geholfen</Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'center' }}><Typography>{helper.helpedBefore && <CheckIcon />}</Typography></Grid>
                                        <Grid item xs={6}>Vereinsmitglied</Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'center' }}><Typography>{helper.isClubMember && <CheckIcon />}</Typography></Grid>
                                      </Grid>
                                    </TableCell>)
                                    : (
                                      <>
                                        <TableCell component='th' scope='row'>
                                          {`${helper.firstName} ${helper.lastName}`}
                                        </TableCell>
                                        <TableCell>
                                          {`${helper.availableOnFriday && !helper.assignedOnFriday ? 'FR' : ''} ${helper.availableOnSaturday && !helper.assignedOnSaturday ? 'SA' : ''} ${helper.availableOnSunday && !helper.assignedOnSunday ? 'SO' : ''}`}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{helper.helpedBefore && <CheckIcon />}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{helper.isClubMember && <CheckIcon />}</TableCell>
                                      </>)}
                                </StyledTableRow>
                              ))}
                            </>
                          )
                          : (
                            <TableCell colSpan={4} sx={{ width: '100%', textAlign: 'center' }}>
                              <Typography variant='body1'>Alle verfügbaren Helfer sind verplant.</Typography>
                            </TableCell>)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </CardComponent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default Dashboard
