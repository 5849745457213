import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, CssBaseline, TextField, Container } from '@mui/material'

import Env from '../config/envConfig'
import { setUser } from '../../store/settings/settings.actions'
import { useDispatch } from 'react-redux'

const Login = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [login, setLogin] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const _onLogin = () => {
    const loginRequest = {
      login,
      password,
    }
    const basePath = Env.basePath
    const restUrl = `${basePath}/api/session/login`

    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    fetch(restUrl,
      {
        method: 'POST',
        body: JSON.stringify(loginRequest),
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(() => {
        const userUrl = `${basePath}/api/session/user`
        fetch(userUrl,
          {
            method: 'GET',
            credentials: 'include',
            headers: requestHeaders,
          })
          .then(res => res.json())
          .then(json => {
            !json.error && dispatch(setUser(json))
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }

  const _onKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      _onLogin()
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div>
        <form noValidate>
          <TextField
            margin='normal'
            required
            fullWidth
            id='login'
            label={t('login:login')}
            name='login'
            autoComplete='login'
            autoFocus
            value={login}
            onChange={(event) => setLogin(event.currentTarget.value)}
            onKeyPress={_onKeyPress}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label={t('login:password')}
            type='password'
            id='password'
            autoComplete='current-password'
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            onKeyPress={_onKeyPress}
          />
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={_onLogin}
          >
            {t('login:login')}
          </Button>
        </form>
      </div>
    </Container>
  )
}
export default Login
