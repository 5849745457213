export const BASE_PATH = window.location.origin
const BASE_PATH_API = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8092'
  : BASE_PATH

const devEnv = {
  crudeRestBase: BASE_PATH_API,
  basePath: BASE_PATH_API,
}

const stageEnv = {
  ...devEnv,
}

const prodEnv = {
  ...devEnv,
}

type Env = typeof devEnv
  | typeof stageEnv
  | typeof prodEnv

const getEnvValues: () => Env = () => {
  switch (process.env.BUILD_ENV) {
    case 'production':
      return prodEnv
    case 'staging':
      return stageEnv
  }
  return devEnv
}

export const isDebugEnv: boolean = process.env.BUILD_ENV !== undefined
  ? process.env.BUILD_ENV === 'development'
  : process.env.NODE_ENV === 'development'

export default getEnvValues()
