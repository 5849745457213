import React, { useState } from 'react'
import {
  Box,
  Button, IconButton, Theme, Typography, useTheme, TextField,
} from '@mui/material'
import { EntityData } from '@3m5/crude-frontend/dist/shared/types/dataTypes'
import Env from '../../shared/config/envConfig'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DialogComponent from '@3m5/crude-frontend/dist/shared/components/MessageComponents/DialogComponent'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'

interface InjectedTableActionComponentProps {
  entity: string
  entityId: string
  treeViewLevel: number
  entityData: EntityData
  sharedData: Record<string, any>
}
const TableActionButtons: React.FC<InjectedTableActionComponentProps> = (props) => {
  const basePath = Env.basePath
  const theme: Theme = useTheme()

  const [showErrorText, setShowErrorText] = useState<boolean>(false)
  const [showPasswordDialog, setShowPasswordDialog] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState<boolean>(false)
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string | undefined>()
  const [oldPassword, setOldPassword] = useState<string | undefined>()
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>()

  const exportFile = (id: string, day: string) => {
    const url = `${basePath}/api/export/area/${day}/${id}`
    fetch(url,
      {
        method: 'GET',
        credentials: 'include',
      })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = `${props.entityData.displayName}${day}Export.xlsx`
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
  }
  const setPassword = () => {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    const url = `${basePath}/api/user/${props.entityId}/password`
    const json = {
      userId: props.entityId,
      newPassword: newPassword,
      oldPassword: oldPassword,
    }
    props.entityId && fetch(url,
      {
        method: 'PUT',
        credentials: 'include',
        headers: requestHeaders,
        body: JSON.stringify(json),
      })
      .then(res => {
        if (res.status < 400) {
          setShowErrorText(false)
          setNewPassword(undefined)
          setOldPassword(undefined)
          setConfirmPassword(undefined)

          setShowPasswordDialog(false)
        } else {
          setShowErrorText(true)
        }
      })
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleClickShowPasswordRepeat = () => setShowPasswordRepeat((show) => !show)

  const handleMouseDownPasswordRepeat = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show)

  const handleMouseDownOldPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <>
      {props.entity === 'Area' &&
        <>
          <Button onClick={() => exportFile(props.entityId, 'FR')}><FileDownloadIcon color='primary' />FR</Button>
          <Button onClick={() => exportFile(props.entityId, 'SA')}><FileDownloadIcon color='primary' />SA</Button>
          <Button onClick={() => exportFile(props.entityId, 'SO')}><FileDownloadIcon color='primary' />SO</Button>
        </>}
      {props.entity === 'User' &&
        <>
          <Button onClick={() => setShowPasswordDialog(true)}>Passwort ändern</Button>
        </>}
      <DialogComponent
        open={showPasswordDialog}
        dialogWidth='400px'
        content={
          <>
            <TextField
              sx={{ margin: '20px 0' }}
              fullWidth
              type={showOldPassword ? 'text' : 'password'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOldPassword(event.target.value)
              }}
              required
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownOldPassword}
                    edge='end'
                  >
                    {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>),
              }}
              label='Altes Passwort'
            />
            <TextField
              fullWidth
              id='outlined-adornment-password'
              type={showPassword ? 'text' : 'password'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewPassword(event.target.value)
              }}
              required
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>),
              }}
              label='Neues Passwort'
            />
            <TextField
              sx={{ margin: '20px 0' }}
              fullWidth
              id='outlined-adornment-password-repeat'
              type={showPasswordRepeat ? 'text' : 'password'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(event.target.value)
              }}
              required
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPasswordRepeat}
                    onMouseDown={handleMouseDownPasswordRepeat}
                    edge='end'
                  >
                    {showPasswordRepeat ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>),
              }}
              label='Neues Passwort wiederholen'
            />
            {showErrorText &&
              <Typography variant='body1' sx={{ color: theme.palette.error.main }}>Es ist ein Fehler aufgetreten! Überprüfen Sie Ihre Eingaben!</Typography>}
            <Box sx={{ marginTop: '10px' }}>
              <Button
                color='secondary'
                disabled={!newPassword}
                onClick={() => {
                  setShowPasswordDialog(false)
                }}
              >Abbrechen
              </Button>
              <Button
                color='primary'
                disabled={!newPassword || newPassword !== confirmPassword}
                onClick={() => {
                  if (newPassword) {
                    setPassword()
                  }
                }}
              >Speichern
              </Button>
            </Box>
          </>
        }
        title={<>Passwort ändern</>}
        request={false}
        onClose={() => setShowPasswordDialog(false)}
        autoClose={false}
      />
    </>
  )
}
export default TableActionButtons
